<template>
  <div id="data-list-list-view" class="data-list-container">
    <vs-alert
      style="text-align: left; margin-bottom: 10px;"
      :active.sync="active1"
      closable
      color="danger"
      close-icon="close"
    >{{serverErrorMessage}}</vs-alert>
    <!-- <vs-button @click="getClinics">Generate Invoice</vs-button> -->
    <vs-popup title="Generate Invoice" :active.sync="popupActivo">
      <vs-select
        style="position: relative;"
        placeholder="Select Clinic"
        autocomplete
        v-model="clinic"
        label="Clinic"
        class="w-full m-2"
      >
        <vs-select-item
          :key="index"
          :value="item._id"
          :text="item.clinicName ? item.clinicName : item.firstName"
          v-for="(item, index) in clinics"
        />
      </vs-select>

      <vs-select
        label="Year"
        class="w-full m-2"
        :placeholder="currentYear"
        autocomplete
        v-model="selectedYear"
      >
        <vs-select-item
          :key="index"
          :value="item"
          :text="item"
          v-for="(item, index) in yearDropdown"
        />
      </vs-select>

      <vs-select
        label="Month"
        class="w-full m-2"
        :placeholder="monthDropdown[currentMonth - 1]"
        autocomplete
        v-model="selectedMonth"
      >
        <vs-select-item
          :key="index"
          :value="item"
          :text="item"
          v-for="(item, index) in monthDropdown"
        />
      </vs-select>

      <vs-button
        @click="generateInvoice()"
        :disabled="clinic === '' || year === '' || month === ''"
        color="primary"
        class="m-2"
      >Generate</vs-button>
    </vs-popup>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ClinicYearMonthPopup",
  data() {
    return {
      popupActivo: false,
      clinics: [],
      clinic: "",
      active1: false,
      serverErrorMessage: "",
      monthDropdown: [
        "Jan",
        "Feb",
        "Mar",
        "April",
        "May",
        "June",
        "July",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      currentMonth: new Date().getMonth() + 1,
      selectedMonth: "",
      currentYear: new Date().getFullYear(),
      selectedYear: new Date().getFullYear(),
      yearDropdown: [],
    };
  },
  methods: {
    ...mapActions("clinic", ["fetchAllClinic", "generateAdminClinicInvoice"]),
    getClinics() {
      this.selectedMonth = this.monthDropdown[new Date().getMonth()];
      this.selectedYear = new Date().getFullYear();
      this.clinic = "";
      this.clinics = [];
      this.$vs.loading();
      this.getYearDropdown();
      let self = this;
      this.popupActivo = true;
      this.fetchAllClinic()
        .then((res) => {
          this.clinics = res.data.data;
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.notify({
            title: "Failed",
            text: err.data.message,
            color: "danger",
          });
          this.$vs.loading.close();
        });
    },
    getYearDropdown() {
      this.yearDropdown = [];
      for (let i = 2020; i <= this.currentYear; i++) {
        this.yearDropdown.push(i);
      }
    },

    async generateInvoice() {
      this.$vs.loading();
      const clinicId = this.clinic;
      const year = this.selectedYear;
      const month = this.selectedMonth;

      let monthNumber = this.monthDropdown.indexOf(month);
      monthNumber++;
      if (monthNumber.toString().length < 2) monthNumber = `0${monthNumber}`;

      await this.generateAdminClinicInvoice({ clinicId, year, monthNumber })
        .then((res) => {
          this.$vs.notify({
            title: "Success",
            text: "Invoice successfully sent.",
            color: "success",
          });
          this.$vs.loading.close();
          this.popupActivo = false;
          this.$emit("fetchData");
        })
        .catch((err) => {
          this.$vs.notify({
            title: "Failed",
            text: err.data.message,
            color: "danger",
          });
          this.$vs.loading.close();
          this.popupActivo = false;

          this.active1 = true;
          this.serverErrorMessage = err.data.message;
          this.$emit("fetchData");
          window.scrollTo(0, 0);
        });
    },
  },
  created() {
    this.selectedMonth = this.monthDropdown[new Date().getMonth()];
    this.selectedYear = new Date().getFullYear();
    this.clinic = "";
  },
};
</script>

<style scoped>
div.vs-select--options {
  position: fixed !important;
}
</style>
