<template>
  <vx-card>
    <div>
      <vs-tabs class="invoice-list">
        <vs-tab label="Clinic">
          <div>
            <ClinicInvoiceList
              @viewInvoiceDetailUpcoming="viewInvoiceDetailUpcoming"
            />
          </div>
        </vs-tab>
        <vs-tab label="Doctors">
          <div>
            <DoctorInvoiceList
            />
          </div>
        </vs-tab>
      </vs-tabs>
    </div>
  </vx-card>
</template>

<script>
import ClinicInvoiceList from "../../components/invoice/listClinicInvoice";
import DoctorInvoiceList from "../../components/invoice/listDoctorInvoice";

export default {
  components: {
    ClinicInvoiceList,
    DoctorInvoiceList
  },
  data() {
    return {
      totalDocs: 0,
      page: 1,
      noDataText: 'Loading...'
    };
  },
  methods: {
    viewInvoiceDetailUpcoming(invoiceId) {
      this.$router.push({
        name: "UpcomingInvoiceDetails",
        params: { id: invoiceId }
      });
    }
  }
};
</script>
